<template>
  <div class="register">
    <div class="register-container">
      <div class="demo-ruleForm">
        <el-tabs v-model="tabsName" type="card">
          <el-tab-pane label="个人修改密码" name="unit">
            <el-form :model="addForm" :rules="rules" ref="addFormRef" label-position="left" label-width="90px" style="margin-top: 20px;">
              <el-form-item prop="phone" label="手机号:">
                <el-input v-model.trim="addForm.phone" placeholder="请输入手机号" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item prop="code" label="验证码:">
                <div style="display: flex;">
                  <el-input v-model.trim="addForm.code" placeholder="请输入短信验证码" maxlength="6"></el-input>
                  <div class="code-btn" >
                    <div style="width: 100%; cursor: pointer;" @click="sendVerificationCode" v-if="!isSending">{{ sendText }}</div>
                    <div v-else>{{ countdown + 's' }}</div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="pwd" label="新密码:">
                <el-input autocomplete="new-password" show-password v-model.trim="addForm.pwd" placeholder="密码格式为8-16位数字字母组合" maxlength="16"></el-input>
              </el-form-item>
              <el-form-item prop="pwds" label="确认密码:">
                <el-input autocomplete="new-password" show-password v-model.trim="addForm.pwds" placeholder="确认密码" maxlength="16"></el-input>
              </el-form-item>
              <el-form-item >
                <el-button style="width: 100%; margin-top: 20px;" type="primary" :loading="addForm.loading" @click="Submit">确定</el-button>
              </el-form-item>
            </el-form>
            <div class="clearfix" style="margin-top: 20px; text-align: right;">
              <el-link type="primary" :underline="false" style="color: #2A73FF;" @click="hanldeBack">返回登录</el-link>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <Verificate :isShow="addForm.isShow" @success="successVer()" @cancel="cancleVer()"></Verificate>
  </div>
</template>

<script>
import md5 from 'js-md5';
import Verificate from '@c/verificate/Verificate.vue';
import { valCodeCreateHY, valCodeRetrievePasswordHY, valCodeValidHY } from "@/http/api/LoginApi";
export default {
  components: { Verificate },
  data() {
    const checkPPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
        if (!reg.test(value)) {
          callback(new Error('密码格式为8-16位数字字母组合'))
        } else {
          callback()
        }
      }
    }
    const checkPPwds = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.addForm.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      tabsName: 'unit',
      sendText: '发送验证码',
      timer: null,
      countdown: 60,
      isSending: false,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误', trigger: ['blur', 'change'] }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: ['blur'] }],
        pwd: [{ required: true, validator: checkPPwd, trigger: ['blur', 'change'] }],
        pwds: [{ required: true, validator: checkPPwds, trigger: ['blur', 'change'] }]
      },
      addForm: {
        isShow: false,
        loading: false,
        phone: '', // 15223452345  17633686742
        code: '',
        pwd: ''
      }
    }
  },
  created () {},
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    hanldeBack() {
      this.$router.push({ path: '/login' });
    },
    Submit() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) return false
        this.addForm.loading = true
        valCodeValidHY({ mobile: this.addForm.phone, valCode: this.addForm.code }).then(res => {
          this.addForm.loading = false
          if (res.result) {
            const copy1 = JSON.parse(JSON.stringify(this.addForm))
            const params = {
              ltype: 'web',
              mobile: copy1.phone,
              valCode: copy1.code,
              password: md5(copy1.pwd),
            }
            valCodeRetrievePasswordHY(params).then(res => {
              if (res.code == '000') {
                this.$message.success('修改成功')
                this.$refs.addFormRef.resetFields()
              }
            })
          } else {
            this.addForm.loading = false
          }
        })
      })
    },
    // 发送验证码
    sendVerificationCode() {
      if (this.isSending) return false
      this.$refs.addFormRef.validateField('phone', valid => {
        if (valid) return false
        this.addForm.isShow = true
      })
    },
    startCountdown() {
      this.isSending = true
      this.timer = setInterval(() => {
        if (this.countdown > 1) {
          this.countdown--
        } else {
          this.sendText = '重新发送'
          this.resetCountdown()
        }
      }, 1000)
    },
    successVer() {
      this.cancleVer()
      valCodeCreateHY({ mobile: this.addForm.phone }).then(res => {
        if (res.code === '000') {
          this.$message.success('验证码发送成功')
          this.startCountdown()
        }
      })
    },
    resetCountdown() {
      clearInterval(this.timer)
      this.isSending = false
      this.countdown = 60
    },
    cancleVer() {
      this.addForm.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.code-btn {
  text-align: center;
  width: 150px;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #409EFF;
  border-radius: 4px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  color: #409EFF;
}
.register {
  width: 100%;
  height: 100vh;
  // background-color: #ddd;
  background: url("../../assets/images/bg.jpg") no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  .register-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // height: 100vh;
    .scroll::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
    h5 {
      font-size: 36px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      margin-bottom: 30px;
    }
    h6 {
      color: #2A73FF;
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 20px;
    }
    .demo-ruleForm {
      width: 600px;
      background: #FFFFFF;
      padding: 30px 50px;
      box-sizing: border-box;
      position: relative;
      border-radius: 4px;
      .location {
        position: absolute;
        bottom: 80px;
        left: 70px;
        right: 70px;
        color: #666;
        cursor: pointer;
      }
      .list {
        line-height: 40px;
        margin-bottom: 20px;
        .item.active {
          color: #2A73FF;
          font-size: 18px;
        }
        .item {
          width: 33%;
          text-align: center;
          color: #333333;
          font-size: 16px;
          span {
            cursor: pointer;
          }
        }
      }
    }
    .box::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 16px;
      top: 12px;
      left: 0;
      background: #D8D8D8;
    }
    .box {
      width: 120px;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      font-size: 14px;
      color: #2A73FF;
    }
  }
}
</style>